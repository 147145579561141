<template>
  <div 
    class="c-index-shop j-config-index transition-page"
    :style="wrapStyle"
  >
    <!-- top tab -->
    <HomeToptab
      ref="HomeToptabRef"
      :channel-id="channelId"
      :tab-list="topTabList"
      :top-tab-color="commonHeaderBg.topTextColor"
      :background-color="commonHeaderBg.backgroundColor"
      :bg-img-src="commonHeaderBg.bgImgSrc"
      :immersive-flag="commonHeaderBg.immersiveFlag"
      :show-block="commonHeaderBg.showBlock"
      :has-immersive-banner="hasImmersiveBanner"
      @switch-tab="switchTabHandle"
    />
    <!-- home-components -->
    <div
      :class="{ 'index-content-container': topTabList && topTabList.length > 1 }"
      :style="indexContainerStyle"
    >
      <template v-for="item in list">
        <!-- 非首页预览可能存在没有channelId -->
        <AppNew
          v-show="Number(item.channelId) === Number(channelId) || isPreview"
          ref="newCccAppReference"
          :key="`new-${item.channelId}`"
          :app-content="item"
          :hooks="cccAppHooks"
          :style="getGradientStyle(item)"
        />
        <!-- ccc推荐组件 -->
        <client-only :key="`ccc-recommend-${item.channelId}`">
          <InformationFlowCcc
            v-for="(subItem, subIndex) in getInformationFlowList(item)"
            v-show="Number(item.channelId) === Number(channelId)"
            :key="`ccc-information-flow-${item.channelId}-${subIndex}`"
            :is-show="Number(item.channelId) === Number(channelId)"
            :prop-data="subItem"
            :context="context"
            :index="Number(subIndex) + 1"
          />
        </client-only>
      </template>
      <!-- loading -->
      <div
        v-if="status.loading"
        class="home-index__loading"
      >
        <div
          v-for="i in 5"
          :key="i"
          class="home-index__holder"
        >
          <s-skeleton animated>
            <template slot="template">
              <s-skeleton-item 
                variant="rect" 
                style="width: 10rem; padding: 20%;border-radius:0;" 
              />
            </template>
          </s-skeleton>
        </div>
      </div>
    </div>
    
    <!-- footer -->
    <client-only>
      <!-- 内容显示了当前元素后才开始渲染footer组件 -->
      <div class="j-foonter-expose-flag"></div>
      <div 
        class="c-index-copyright-container"
      >
        <footer-info
          class="c-index-copyright-container__shein-info"
          :title-required="true"
          :channel-id="channelId"
          arrow="content"
          data-type="home_bottom"
          :analysis-info="{ eventCategory: 'Home', exposeCode: 'home' }"
        />
      </div>

      <!-- 首页悬浮角标 -->
      <suspension-icon
        v-if="floatingIconDataSource === 'ccc' && !floatingIconPreview"
        :channel-name="context.channelName"
      />
      <!-- 首页悬浮角标 新cccx -->
      <Floating-icon
        v-if="floatingIconDataSource === 'cccx' && floatingIconAbt || floatingIconPreview"
        :channel-name="context.content?.channelName"
        :floatingIconAbt="floatingIconAbt"
      />
    </client-only>
    <!-- 底部悬浮组件 --> 

    <template v-for="item in list">
      <bottom-suspension-icon
        v-if="(
          item?.hoverComponentResponse?.[0]
          && Number(item.channelId) === Number(channelId)
        )"
        v-show="!hideBottomSuspension"
        :key="`bottom-suspension-${item.channelId}`"
        :is-visible="Number(item.channelId) === Number(channelId)"
        :app-content="item"
        :prop-data="item?.hoverComponentResponse?.[0] || {}"
      />
    </template>
    <client-only>
      <HomePopover 
        @close-status-change="handlePopoverCloseStatusChange"
      />
    </client-only>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import TMG from '@shein/time-management-guru'
import { Skeleton as SSkeleton, SkeletonItem as SSkeletonItem } from '@shein/sui-mobile'
import { getQueryString, transformImg } from '@shein/common-function'
import { commonComponents } from './common/comMapping'
import renderFrameMixin from 'public/src/pages/common/renderFrameMixin'
import AppNew from './AppNew.vue'

import { daEventCenter } from 'public/src/services/eventCenter/index'
import { transfromAndCutImg } from 'public/src/services/resource/index'
daEventCenter.addSubscriber({ modulecode: '2-2' })
daEventCenter.addSubscriber({ modulecode: '2-22' })

import layoutAnalysis from './analysis'
import { cccxEventBus, hexToRGBA, handleColor } from 'public/src/pages/components/ccc/common/utils.js'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import HomePopover from './components/HomePopover.vue'
import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'

let isFromSecondaryHome = false

export default {
  name: 'HomePageEntry',
  components: {
    HomePopover,
    ...commonComponents,
    // AppOld,
    AppNew,
    SSkeleton,
    SSkeletonItem,
  },
  mixins: [renderFrameMixin(2)],
  provide() {
    const { homeProductJumpAbtData = {} } = this.context
    return {
      isSecondaryHome: false,
      status: this.status,
      cutImg: this.cutImg,
      homeProductJump: homeProductJumpAbtData?.param?.HomeProductJump || '',
      metricsConfig: { // 监控配置
        reportMetrics: {
          cccClick: true,
        },
        pageName: 'page_home',
      }
    }
  },
  // SSR CSR 调用 将数据注入仓库
  async asyncData ({ store, context }) {
    // 从二级首页刷新回到首页,需要刷新数据
    // let isSameChannelId = true // 当前路由 channelId 和 store 里的 channelId 是否一致
  
    // if (typeof window !== 'undefined') {
    //   const activeChannelId = getQueryString({ key: 'activeChannelId' })
    //
    //   if (activeChannelId) {
    //     isSameChannelId = activeChannelId == context?.channelId
    //   } else {
    //     // 兼容旧路由
    //     let channelId = ''
    //     const topTabList = context?.topTabList || []
    //     for (const item of topTabList) {
    //       const link = item.url
    //       if (link && link.endsWith(window.location?.pathname)) {
    //         channelId = item.channelId 
    //         break
    //       }
    //     }
    //     channelId = channelId || topTabList?.[0]?.channelId || ''
    //     isSameChannelId = channelId == context?.channelId
    //   }
    //  
    // }
  
    // ssr或者客户端数据直出直接赋值
    if (context && context.ssrPageType === 'config_index') {
      store.state['config_index'].context = context
      const { homePageTerminal } = context
      // HomePageTerminal.p.H1PictureProcess === 'H1_picture' 显示H1商品容器为5:6，否则商品容器3:4
      store.state['config_index'].isH1R56 = homePageTerminal?.p?.H1PictureProcess === 'H1_picture'
      // HomePageTerminal.p.HomePictureProcess === 'Home_picture' 其他商品图容器为5:6,否则3:4
      store.state['config_index'].isHomeR56 = homePageTerminal?.p?.HomePictureProcess === 'Home_picture'
      const hasImmersiveBanner = context?.content?.content?.[0]?.styleType === 'IMMERSIVE_BANNER'
      const extension = JSON.parse(context?.content?.extension || '{}')
      const whiteColorRegExp = /^#([fF]{3}|[fF]{6})$/
      const flag = (!extension?.backgroundColor && !extension?.backgroundImgSrc?.src) || (!extension?.backgroundImgSrc?.src && whiteColorRegExp.test(extension?.backgroundColor) && whiteColorRegExp.test(extension?.topTextColor))
      // if(flag) return
      if (hasImmersiveBanner || extension.backgroundColor || extension?.backgroundImgSrc?.src) {
        store.state.hasImmersiveBanner = hasImmersiveBanner
        store.state.commonHeaderBg = {
          bgImgSrc: hasImmersiveBanner ? '' : extension?.backgroundImgSrc?.src, // 配置了沉浸式，则不展示全局背景图，但是要展示全局背景色
          showBlock: hasImmersiveBanner || !flag, // 沉浸式开关
          topTextColor: hasImmersiveBanner ? '#FFFFFF' : extension?.topTextColor, // 目前可写死 自动聚合、清晰 => #ffffff
          immersiveFlag: hasImmersiveBanner,
          backgroundColor: (extension.backgroundColor && hexToRGBA(handleColor(extension.backgroundColor))) || '',
        }
      }
      return Promise.resolve(context)
    } else {
      try {
        if (typeof window === 'undefined') throw new Error('can\'t found context for ssr')
        // 客户端渲染，需要拿到页面所有的数据
        return store.dispatch('config_index/FETCH_PAGE_DATA', {
          activeChannelId: getQueryString({ key: 'activeChannelId' }),
          oldRoute: window.location?.pathname,
        })
      } catch (error) {
        console.log(error)
      }
    }
  },
  data() {
    return {
      bannerList: {}, // 存储所有 Tab 的首页沉浸式组件的数据
      status: {
        loading: false,
      },
      cccTabShow: {},
      userCrowdId: '',
      gbBgHeight: 0,
      hideBottomSuspension: false,
    }
  },
  computed: {
    ...mapState(['redpoint', 'commonHeaderBg', 'commonHeight']),
    ...mapState('config_index', ['context', 'list', 'isHomeR56']),
    ...mapGetters('config_index', ['content', 'channelId', 'topTabList', 'topTabAbtData', 'hasPolicyBanner', 'policyBannerPlaceholder', 'freeShipping', 'immersiveBannerContent']),
    wrapStyle(){
      const styleObj = { backgroundColor: (this.commonHeaderBg?.backgroundColor) || '#fff' }
      if(this.isHomeR56){
        /* 首页商卡组件图裁剪样式 TR-37950 */
        styleObj['--crop-img-fit'] = 'cover'
      }
      return styleObj
    },
    isPreview() {
      return this.context?.isPreview || false
    },
    bannerChannelList() {
      return Object.keys(this.bannerList)
    },
    indexContainerStyle() {
      const style = {
        position: 'relative',
      }
      if(this.commonHeaderBg?.bgImgSrc){
        Object.assign(style,  { backgroundImage: `url(${ this.commonHeaderBg?.bgImgSrc })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: `center ${-this.commonHeight?.topBgHeight}px`,
          backgroundSize: `auto ${ this.commonHeight.bottomBgHeight + this.commonHeight?.topBgHeight}px` },)
      }
      return style
    },
    cccAppHooks() {
      return {
        IMMERSIVEBANNER: {
          // context_params参数
          generateContextParams: () => {
            return {
              status: this.status
            }
          },
        },
        // 品类推荐组件
        CATEGORYRECOMMENDDYNAMIC: {
          // context_params参数
          generateContextParams: () => {
            return {
              isFirstTab: this.topTabList?.[0]?.channelId === this.channelId
            }
          },
        },
      }
    },
    hasImmersiveBanner(){
      return this.list?.[this.channelId]?.content?.[0]?.styleType === 'IMMERSIVE_BANNER'
    },
    BackgroundGradient () {
      return this.context?.homePageTerminal?.p?.BackgroundGradient || ''
    },
    floatingIconDataSource() {
      return this.context?.homePageFloatingIconExpAbtData?.param?.DataSource || 'ccc'
    },
    floatingIconAbt() {
      let abtInfo = null
      if (this.context?.homePageFloatingIconExpAbtData?.param?.IfShowComponent === 'true'){ // IfShowComponent 是否展示整个组件
        abtInfo = JSON.parse(JSON.stringify(this.context?.homePageFloatingIconExpAbtData?.param)) 
      }
      return abtInfo
    },
    floatingIconPreview() {
      return this.$route?.query?.contentId && this.$route?.query?.pageType === 'floatingIcon'
    },
  },
  watch: {
    $route(to, from){
      // 不需要展示公共头部的无需隐藏
      if(!from?.meta?.noNeedCommonHeader && to?.name !== 'config_index') {
        this.commonHeaderBg.showBlock = false
      }
    },
    hasImmersiveBanner(val){
      this.changeHomeImmersiveInfo({ showImmersiveBanner: val })
    },
  },
  beforeRouteEnter(to, from, next) {
    // 从专题点击logo跳转首页处理
    if (typeof window !== 'undefined' && from.name === 'campaigns' && window.appRouteExtData?.direction === 0 && !window.JOURNEY_BRANCH?.close) {
      window.scrollTo(0, 0)
    }
    isFromSecondaryHome = from.name === 'secondary_home'
    next(vm => {
      // 从二级首页回到首页（根据首页之前的频道重新请求数据） 
      if (from.name === 'secondary_home' && to.name === 'config_index') {
      
        let channelId = ''
        let channelName = ''
        let channelInfo = {}
        // 首页新路由
        if (to.query?.activeChannelId) {
          channelId = Number(to.query?.activeChannelId)
          channelInfo = vm.topTabList?.find(i => i.channelId === channelId)
          channelName = vm.topTabList?.find(i => i.channelId === channelId)?.channelName
        } else { // 旧路由
          for (const item of vm.topTabList) {
            const link = item.url
            if (link && link.endsWith(to.path)) {
              channelName = item.channelName
              channelId = item.channelId
              break
            }
          }
        }
        // 如果没有匹配到数据默认用第一个tab的数据
        channelId = channelId || vm.topTabList?.[0]?.channelId || ''
        channelName = channelName || vm.topTabList?.[0]?.channelName || ''
        vm.querySenceData?.(channelId, channelName, channelInfo).then(() => {
          // 1. 刷新页面埋点
          vm.refreshAnalysis()
          // 2. 发送pv
          window.appEventCenter?.$emit('pageOnload')
          window.TPM?.publish('viewhome')
          vm.channelId == channelId && vm.list[channelId]?.extension && !vm.hasImmersiveBanner && vm.handleIndexBgInfo(vm.list[channelId].extension)
        })
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    // 重排： 跳转分类页
    from.meta.savedPosition = { x: 0, y: $(window).scrollTop() }
    next()
  },
  created() {
    if (isFromSecondaryHome) {
      this.$store.state['config_index'].context.channelId = this.getCurrentChannelId()
    }
   
    this.hasRequestChannelIds = new Set()
    // 在当前vue中做一个缓存
    if (this.content?.channelId == this.channelId) {
      this.setBannerListCache(this.immersiveBannerContent, this.channelId)
      // 存储请求过的频道数据
      this.assignList({ channelId: this.channelId, content: this.content })
      this.hasRequestChannelIds.add(this.channelId)
    } else if(this.isPreview) {
      // 预览
      this.assignList({ channelId: this.channelId, content: this.content })
    }
    // 更新资源sdk信息
    this.resetResourceSDK()
  },
  mounted() {
    // 在首页预请求用户即将访问的其他页面的 js 资源
    this.prefetchResources()
    this.setMonitor(this.content)

    // 红点曝光上报
    appEventCenter.$on('redpointCheckDone', () => {
      if (this.$route.name !== 'config_index') {
        return
      }

      daEventCenter.triggerNotice({
        daId: '2-2-22',
        extraData: {
          redpoint: this.redpoint
        }
      })
    })

    this.preRequestNextChannelData(this.channelId)
  },
  activated() {
    // 人群id = 原本有的 ｜｜ 链接中携带 ｜｜ 实验中携带
    this.userCrowdId = this.userCrowdId ?
      this.userCrowdId :
      this.$route?.query?.userCrowdId || this.context.acCrowdIds

    this.refreshAnalysis()

    if (typeof window !== 'undefined') {
      window.appEventCenter?.$emit?.('pageOnload')
      window.TPM?.publish?.('viewhome')

      $('.j-index-footer .j-index-tab-list-home').addClass('title-active').siblings().removeClass('title-active')
      $('.j-dt-go-category-index, .j-header-msg').show()
      $('.j-dt-go-category-list-detail').hide()

      this.$store?.commit?.('changeSeo', this.context.pageListInfo)
    }
    cccxEventBus?.emit?.('freeShippingPage-will-hidden')
    // this.list[this.channelId] 在created中设置
    typeof window !== 'undefined' && this.list[this.channelId]?.extension && !this.hasImmersiveBanner && this.handleIndexBgInfo(this.list[this.channelId].extension)
  },
  deactivated() {
    cccxEventBus?.emit?.('freeShippingPage-will-hidden')
    this.commonHeaderBg.showBlock = false
  },
  destroyed () {
    this.$store?.unregisterModule?.('config_index')
    cccxEventBus?.off?.('register-sticky-shipping')
    cccxEventBus?.off?.('freeShippingPage-will-hidden')
    this.commonHeaderBg.showBlock = false
  },
  methods: {
    ...mapMutations('config_index', ['assignList']),
    ...mapMutations(['changeHomeImmersiveInfo', 'changeRootStatus']),
    handleIndexBgInfo(contentExtension){
      try {
        const whiteColorRegExp = /^#([fF]{3}|[fF]{6})$/
        const extension = JSON.parse(contentExtension ?? '{}')
        // 计算背景图相关信息
        const imgSrc = extension?.backgroundImgSrc?.src && transformImg( { img: extension?.backgroundImgSrc?.src })
        const backgroundColor = extension?.backgroundColor
        const topTextColor = extension?.topTextColor
        // 1. 无背景色 且 无背景颜色 => return不生效沉浸式
        // 或者2. 无背景图 且 页面底色配了白色 且 文本颜色配了白色 => return不生效沉浸式
        if((!backgroundColor && !imgSrc) || (!imgSrc && whiteColorRegExp.test(backgroundColor) && whiteColorRegExp.test(topTextColor))) {
          this.commonHeaderBg.showBlock = false
          return
        }
        this.changeHomeImmersiveInfo({
          commonHeaderBg: {
            showBlock: true, // 沉浸式开关
            // 要配置了图片、背景色、字色时，才生效
            topTextColor, // 背景颜色或图片存在 生效字体
            bgImgSrc: imgSrc, // 如果背景色不存在，则不需要展示背景图了，走线上
            backgroundColor: (backgroundColor && hexToRGBA(handleColor(extension?.backgroundColor))) || '',
          }
        })
        
        // 如果存在高度，不需要执行
        // if(this.commonHeight?.topBgHeight) return
        // 直接计算，获取dom时机在不同页面有差异
        const htmlFontSize = parseFloat(getComputedStyle(document.documentElement)?.fontSize) ?? 0
        const backgroundImgSrc = extension?.backgroundImgSrc || {} // {height,width,src}
        const headerHeight = 1.17 * htmlFontSize
        // 无tab的情况
        const tabHeight  = this.topTabList?.length > 1 ? 1.17 * htmlFontSize : 0
        const topBgHeight = headerHeight + tabHeight
        const topHeaderHeight = headerHeight
        const topTabHeight = tabHeight
        const ratio = (backgroundImgSrc?.width / document?.documentElement?.clientWidth) || 1
        const bottomBgHeight = (backgroundImgSrc.height / ratio) - topBgHeight
        this.changeHomeImmersiveInfo({
          commonHeight: {
            topBgHeight,
            topHeaderHeight,
            topTabHeight,
            bottomBgHeight,
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    // 由于二级首页和首页共享一个store，在二级首页刷新时，走了 ssr，全局的 channelId 改变，回到首页需要重置为当前路由对应的 channelId
    getCurrentChannelId() {
      let channelId = ''
      // 首页新路由
      if (this.$route.query?.activeChannelId) {
        channelId = Number(this.$route.query?.activeChannelId)
      } else { // 旧路由
        for (const item of this.topTabList) {
          const link = item.url
          if (link && link.endsWith(this.$route.path)) {
            channelId = item.channelId
            break
          }
        }
      }
      // 如果没有匹配到数据默认用第一个tab的数据
      channelId = channelId || this.topTabList?.[0]?.channelId || ''

      return channelId
    },
    prefetchResources() {
      // el 必须是渲染完的
      prefetchResource.listen({
        el: this.$el,
        prefetchList: [
          // 列表
          {
            chunkName: 'plv2_container',
            relType: 'prefetch'
          },
          // 个人中心子路由容器
          {
            chunkName: 'user',
            relType: 'prefetch'
          },
          {
            chunkName: 'user-index',
            relType: 'prefetch'
          },
        ],
        prefetchCallback: ({ status, info }) => {
          console.log('prefetchCallback', status, info)
        },
        delay: 5000, // 默认两秒
        ioCallback: () => {
          // console.log('ioCallback')
          // 数据预取用
        }
      })
    },

    // tab数据的切换
    async switchTabHandle(result) {
      const { channelId, url: domain, channelName } = result || {}
     
      if (!domain) return console.log('lack domain')
      cccxEventBus?.emit?.('freeShippingPage-will-hidden')

      // 先清空
      if (Object.keys(this.commonHeaderBg).length) this.changeHomeImmersiveInfo({ commonHeaderBg: { showBlock: false } })
      // 1. 路由切换
      const url = `${this.langPath}?activeChannelId=${channelId}`
      this.$router.replace(url)
      // 3. 保存频道ID
      const { lang } = typeof window !== 'undefined' ? window.gbCommonInfo : {}
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(`${lang}-pwa-home-channel`, JSON.stringify(channelId))
      }
      
      // 4. 请求对应频道数据
      this.querySenceData?.(channelId, channelName, result).then(() => {
        // 1. 刷新页面埋点
        this.refreshAnalysis()
        // 2. 发送pv
        window.appEventCenter?.$emit('pageOnload')
        window.TPM?.publish('viewhome')
        this.channelId == channelId && this.list[channelId]?.extension && !this.hasImmersiveBanner && this.handleIndexBgInfo(this.list[channelId].extension)
      })
    },

    /**
     * 查询对应的频道首页的配置
     */
    async querySenceData(channelId, channelName, channelInfo = {}) {
      if (!channelId) return Promise.resolve()
      // 1. 更新 vuex 中的场景值数据
      this.$store.state['config_index'].context.channelId = channelId
      this.$store.state['config_index'].context.channelName = channelName
      await this.$nextTick()
      // 2. 请求数据
      if (!this.list[channelId]) {
        this.status.loading = true
        try {
          this.hasRequestChannelIds.add(channelId)
          const res = await TMG.useQuickRequest(`configIndex/getCccIndexPageContent/${channelId}`, {
            url: '/api/home/switchChannel/get',
            switchChannelName: channelName,
            switchChannelId: channelId,
            channelInfo,
          })
          // 异步请求只会给到banner的数据更新
          if (res && res.content) {
            this.$store.state['config_index'].context.clientRender = true
            this.$store.state['config_index'].context.content = res.content
            this.$store.state['config_index'].context.immersiveBannerContent = res.immersiveBannerContent || {}
           
            // 存储请求过的频道数据
            this.assignList({ channelId, content: res.content })
          }
          this.setMonitor(res?.content || {})

          this.status.loading = false
         
        } catch (error) {
          this.status.loading = false
        }
      }
      // 预请求下一个频道的数据
      this.preRequestNextChannelData(channelId)
      // 从二级首页回来不需要会到顶部
      if (!isFromSecondaryHome) {
        window?.scrollTo(0, -44) // 手动执行，负数撑顶
      }
      
      // 返回一个 resolve 状态
      return Promise.resolve()
    },
    // 信息流组件
    getInformationFlowList(item) {
      const informationFlowConfig = item.cccxConfig?.tabList?.[0]
      const content = informationFlowConfig?.pageContentResponse?.content || []
      const isMatched = content.some(i => i.componentKey === 'INFORMATION_FLOW' && i.styleType === 'INFORMATION_FLOW_DOUBLE_COLUMN')

      if (informationFlowConfig && isMatched) {
        return [informationFlowConfig]
      } else {
        return []
      }
    },

    // 沉浸式组件数据缓存
    setBannerListCache(compData, channelId) {
      if (!Object.keys(compData).length) return
      this.$set(this.bannerList, channelId, compData)
    },

    /**
     * 重置页面埋点信息
     */
    refreshAnalysis () {
      this.$refs['newCccAppReference']?.length && this.$refs['newCccAppReference'].forEach(_ => _.refreshAnalysis?.())

      let index = 0
      const item = this.topTabList?.find((item, i) => {
        if (this.channelId === item.channelId) {
          index = i
          return item
        }
      })
      
      item && layoutAnalysis.refreshAnalysisParams({
        params: {
          tab_id: item.channelId,
          tab_title: item.channelName,
          tab_hole: index + 1,
          tab_crowd_id: this.userCrowdId,
          ccc_abt_type: item.channelId ? (this.list?.[item.channelId]?.abtBranch || '') : '',
        }
      })
    },

    resetResourceSDK () {
      if (typeof window === 'undefined') return
      const { RESOURCE_SDK } = gbCommonInfo
      if  (!this.context.RESOURCE_SDK && RESOURCE_SDK) {
        this.$store.state['config_index'].context.RESOURCE_SDK = RESOURCE_SDK
      }
    },

    /** 图片裁切 */
    cutImg (imgUrl, designWidth, exp) {
      const { RESOURCE_SDK = {} } = this.context || {}
      const { deviceData = '', isSupportWeb = '', isSupprotCut = false, sceneMap = {} } = RESOURCE_SDK || {}

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        sceneMap,
        exp,
      }

      return transfromAndCutImg(cutData)
    },
    /**
     * 获取查询下一个频道的信息
     */
    getQueryChannelInfo(channelId) {
      if (this.topTabList?.length) {
        const switchTabIndex = this.topTabList.findIndex(i => i.channelId === channelId)

        const preRequestTabIndex = switchTabIndex === this.topTabList.length - 1 ? switchTabIndex - 1 : switchTabIndex + 1
        return this.topTabList[preRequestTabIndex]
      }

      return null
    },
    getGradientStyle(item) {
      // 检查信息流数据是否存在
      const informationFlowList = this.getInformationFlowList(item)
      if (informationFlowList.length === 0) {
        return ''
      }
      const bgColor = this.commonHeaderBg && this.commonHeaderBg.backgroundColor ? this.commonHeaderBg.backgroundColor : '#fff'
      const defaultGradient = `background: linear-gradient(to top, #F6F6F6 0px, ${bgColor} 152px, ${bgColor});`
      if (!this.BackgroundGradient) {
        return ''
      }
      if (this.BackgroundGradient === 'show') {
        return defaultGradient
      }
      const ids = this.BackgroundGradient.split(',').map(id => Number(id.trim()))
      if (item.channelId && ids.includes(Number(item.channelId))) {
        return defaultGradient
      }
      return ''
    },
    /**
     * 预请求下一个频道的数据
     */
    preRequestNextChannelData (channelId) {
      const preRequestTabData = this.getQueryChannelInfo(channelId)
      if (!preRequestTabData) return
      requestIdleCallback(() => {
        const nextChannelId = preRequestTabData.channelId
        
        if (this.hasRequestChannelIds.has(nextChannelId)) return
        this.hasRequestChannelIds.add(nextChannelId)

        TMG.triggerQuickRequests('configIndex', {
          quickRequestNames: [`getCccIndexPageContent/${nextChannelId}`],
          triggerParams: { preRequestTabData }
        })
      })
    },
    handlePopoverCloseStatusChange(status) {
      // 优先级：登录条>待支付>一键购>购物车气泡>好看气泡（前面均为 Popover） > 吸底banner
      // 必须所有的 Popover 都关闭，才可以显示吸底 banner
      const isCloseAllPopover = Object.values(status).every(Boolean) // 指每一项都为 true
      this.hideBottomSuspension = !isCloseAllPopover
    },
    // 业务埋点监控
    setMonitor (contextData = {}) {
      metricPageSuccess({ 
        page: 'page_home',
        status: (contextData?.content?.length) ? '1' : '0',
      })
    },
  },
}
</script>

<style lang="less">
@zindex-hack-negative: -1;
@zindex-zero: 0;
@zindex-hack: 1;
.c-index__bg{
   position: absolute;
   width: 100%;
   top: 0;
   z-index: @zindex-hack-negative;
  
  .c-index__bg-bottom{
    top:0;
    left: 0;
    width: 100%;
    z-index: @zindex-zero;
    position: absolute;
    background-repeat: no-repeat; /* 垂直方向无缝重复 */
    background-size: 100% auto; /* 设置背景图片宽度为100%，高度自适应 */
    background-position: 0 -88px; /* 将背景图片的起始位置设置在顶部 */
  }
}
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.c-index-shop {
  position: relative;
  padding-bottom: 50px;
  background-color: #fff;
  // &.tab-hidden {
  //   visibility: hidden;
  //   pointer-events: none;
  // }
}
.c-index-copyright-container{
  padding: 0.64rem 0.64rem 0.53rem;
  background-color: #f6f6f6;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
  // .c-index-shein-info{
  //   border-bottom: 0.026rem solid #E5E5E5;
  //   text-align: center;
  // }
}
.index-content-container{
  min-height: 600px;
}

.j-config-index {
  .product-item__main-img {
    width: 100%;
    // height: auto;
  }
  // 黑五氛围图
  .black-friday-logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  // img {
  //   width: 100%;
  //   border: 0;
  //   // height: initial;
  //   height: 100%;
  // }
  .index-image {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background-size: cover;
    &.index-image-4-normal {
        background-color: #fff;
        .index-image-item {
            &:last-child {
              padding-right: 0;
            }
        }
        .lazyload-irregular-ctn {
          box-shadow: initial;
        }
    }
    .index-countdown-double {
        margin: 0 .32rem;
        + .index-countdown-double {
            margin-left: 0;
        }
    }
    .index-image-item {
      position: relative;
      flex: 1;
      .item-image {
        height: 100%;
        display: block;
        img {
          display: block;
        }
      }
    }
    .index-image-item.image-with-title {
      display: inline-block;
      text-align: center;
      padding: .2rem 0;
      text-decoration: none;
      width: 25%;
      &:first-of-type {
        margin-left: .32rem;
      }
      &:last-of-type {
        margin-right: .32rem;
      }
    }
    .item-title {
      display: block;
      margin: 0 0.1067rem;
      .font-dpr(24px);
      color: #222;
      height: fit-content;
      word-wrap: break-word;
      text-align: center;
      .line-camp();
    }
    .image-icon {
      overflow: hidden;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
}

.home-index__holder {
  margin-bottom: 10px;
}

.home-index__loading {
 
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: @zindex-hack;
}
</style>
