<template>
  <div
    v-show="realShow"
    class="one-pay-container"
    :style="{
      zIndex,
      bottom: bottom
    }"
  >
    <div
      class="one-pay__close"
      @click="closeHandle"
    ></div>
    <!-- 折叠状态 -->
    <div 
      class="one-pay-bg"
      :style="{
        display: isCollaspse ? 'block' : 'none'
      }"
    >
    </div>
    <div
      class="one-pay-fold"
      :style="{
        display: isCollaspse ? 'flex' : 'none'
      }"
      @click="handlerGoToHandPage"
    >
      <div class="shipping-pic">
        <div class="shipping-pic__img"></div>
      </div>
      <div class="shipping-timer">
        {{ timeObj.H || '00' }}:{{ timeObj.M || '00' }}:{{ timeObj.S || '00' }}
      </div>
      <div class="shipping-btn">
        {{ languageInfo.SHEIN_KEY_PWA_28853 }}
      </div>
    </div>

    <!-- 展开状态 -->
    <div
      class="one-pay"
      :style="{
        display: isCollaspse ? 'none' : 'flex'
      }"
      @click="handlerGoToHandPage"
    >
      <div class="one-pay__recommend">
        <template v-if="!apoloConfigClosed && productList.length >= 2">
          <div
            v-for="(item, index) in productList"
            :key="index"
            class="one-pay__recommend-pic"
          >
            <img
              :src="item.goods_img"
              class="image"
            />
            <div class="price-tag">
              {{ item.price }}
            </div>
          </div>
        </template>
        <div
          v-else
          class="one-pay__recommend-pic no-goods"
        >
        </div>
      </div>
      <div class="one-pay__detail">
        <div class="one-pay__detail-title">
          One-Click Pay to enjoy Free shipping
        </div>
        <div class="one-pay__detail-timer">
          <div class="timer-block">
            {{ timeObj.H || '00' }}
          </div>
          <div class="timer-between">
            :
          </div>
          <div class="timer-block">
            {{ timeObj.M || '00' }}
          </div>
          <div class="timer-between">
            :
          </div>
          <div class="timer-block">
            {{ timeObj.S || '00' }}
          </div>
        </div>
      </div>
      <div class="one-pay__btn">
        <div class="one-pay__btn-add">
          {{ languageInfo.SHEIN_KEY_PWA_29463 }}
        </div>
        <div class="one-pay__btn-shipping">
          {{ languageInfo.SHEIN_KEY_PWA_25453 }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gsap } from 'gsap'
import { CountDown } from '@shein/common-function'
import { checkQualificationForNoGoods } from '../fetcher'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { ref } from 'vue'
// import { resMock } from './mock'
daEventCenter.addSubscriber({ modulecode: '2-25' })
let countTimer = ref(new CountDown())
const { langPath } = gbCommonInfo

// 首页 - 一键购组件
export default {
  name: 'OneClickBuy',
  props: {
    // 是否展示组件
    isShow: {
      type: Boolean,
      default: false
    },
    // 距离底部的高度
    bottom: {
      type: String,
      default: ''
    },
    // 页面来源
    scene: {
      type: String,
      default: ''
    },
    zIndex: {
      type: Number,
      default: 9
    },
    // 是否一开始就默认展示折叠状态
    isFold: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      countDownEndFlag: false, // 是否倒计时结束
      isComShowFlag: false, // 是否显示组件
      isCollaspse: false, // 是否折叠
      billno: '', // 订单号
      apoloConfigClosed: false, // 是否关闭apollo配置
      productList: [], // 商品列表
      isAR: false, // 是否是阿拉伯语
      btnAni: null // Add More 按钮呼吸动画
    }
  },
  computed: {
    realShow() {
      return this.isShow && this.isComShowFlag
    },
    timeObj() {
      return countTimer?.value?.timeObj || {}
    }
  },
  watch: {
    '$route': function() {
      this.btnAni?.kill()
    },
    realShow(val) {
      if(val) {
        this.appearAnimation()
      }
    }
  },
  setup() {
    const languageInfo = ref({})
    return {
      languageInfo
    }
  },
  mounted() {
    this.isAR =  gbCommonInfo.GB_cssRight || false
    this.isCollaspse = this.isFold
    if(this.realShow) {
      this.appearAnimation()
    }

    // 获取支付资格
    this.handlerQualificationForNoGoods()
  },
  methods: {
    // 组件出现时的动画
    appearAnimation() {
      if(this.isCollaspse) {
        // 创建一个时间线
        let tl = gsap.timeline()
        tl.to('.one-pay-container', {
          x: this.isAR ? '-1.38667rem' : '1.38667rem',
          duration: 0,
          ease: 'none'
        })

        tl.to('.one-pay-container', {
          x: 0,
          duration: 0.5,
          ease: 'none',
          onComplete: ()=> {
            tl.kill()
          }
        })
      } else {
        // Add More 按钮呼吸动画
        this.handlerBtnBreathe()
      }
    },

    // 获取一键购资格信息
    async handlerQualificationForNoGoods() {
      const res = await checkQualificationForNoGoods({ type: 'home' })
      // const res = resMock
      // 多语言
      this.languageInfo = res?.language || {}
      // 是否关闭apollo配置
      this.apoloConfigClosed = res?.apoloConfigClosed
      // 未登录
      const isNotLogin = !res?.showComponent && res?.reason == -6
      // 是否支持一键购
      const supportOneClickPay = res?.checkInfo?.support_one_click_pay
      // 场景埋点参数
      let one_click_pay_status = supportOneClickPay ? 1 : 0
      if (isNotLogin) {
        one_click_pay_status = 0
      }
      // 场景埋点
      daEventCenter.triggerNotice({
        daId: '2-25-13',
        extraData: {
          scenes: 'one_click_pay',
          one_click_pay_status,
          one_click_pay_reason: !one_click_pay_status ? res?.reason : ''
        }
      })

      // 是否展示组件
      const showComponentVal = res?.showComponent
      if (showComponentVal) {
        // 曝光埋点
        daEventCenter.triggerNotice({
          daId: '2-25-14',
          extraData: {
            module_activity_from: 'home_' + window?.SaPageInfo?.page_name
          }
        })

        // 推荐商品
        if(res?.productList?.length >= 2) {
          this.productList = res?.productList.slice(0, 2)
        }

        // 倒计时
        this.getShortestCountdown(res?.holdInfo?.effectiveTime)

        // 展示组件
        this.isComShowFlag = true
      }

      // 组件加载完成返回消息给父组件
      const hasShow = this.isComShowFlag
      this.$emit('load-end', hasShow ? 1 : 2)

      // 订单号
      this.billno = res?.lastPayOrderBillno
    },

    getShortestCountdown(effectiveTime) {
      countTimer?.value.start({
        seconds: effectiveTime,
        endFunc: () => {
          // 关闭组件
          this.timeToCloseHandle()
        }
      })
    },
    // Add More 按钮呼吸动画
    handlerBtnBreathe() {
      this.btnAni = gsap.to('.one-pay__btn', {
        scale: 1.05,
        repeat: -1,
        yoyo: true,
        duration: 0.5,
        ease: 'power1.inOut'
      })
    },

    // 倒计时关闭组件
    timeToCloseHandle() {
      this.isComShowFlag = false
      this.$emit('close-handle', 'time')
    },

    // 手动关闭组件
    closeHandle() {
      const that = this
      daEventCenter.triggerNotice({
        daId: '2-25-15',
        extraData: {
          click_type: 'close',
          status: that.isCollaspse ? 'fold' : 'unfold'
        }
      })
      this.isComShowFlag = false
      this.$emit('close-handle', 'active')
    },

    // 跳转到一键购落地页
    handlerGoToHandPage() {
      const that = this
      daEventCenter.triggerNotice({
        daId: '2-25-15',
        extraData: {
          click_type: 'open',
          status: that.isCollaspse ? 'fold' : 'unfold'
        }
      })
      const src_module = 'one_click_pay',
            src_identifier = window?.SaPageInfo?.page_name,
            src_tab_page_id = `${window?.SaPageInfo?.page_name}${window?.SaPageInfo?.start_time}`
      
      // 跳转一键购落地页
      window.location.href = `${langPath}/pay/ocp?billno=${this.billno}&src_module=${src_module}&src_identifier=${src_identifier}&src_tab_page_id=${src_tab_page_id}`
    },

    // 弹框收起
    packUpHandle() {
      this.btnAni?.kill()
      if(this.isCollaspse) return
      setTimeout(() => {
        this.isCollaspse = true
      }, 500)

      // 创建一个时间线
      let tl = gsap.timeline()

      gsap.set('.one-pay', {
        opacity: 0.3
      })

      // 将 .one-pay-container 向右移出屏幕
      tl.to('.one-pay-container', {
        x: this.isAR ? '-100vw' : '100vw',
        duration: 0.5,
        ease: 'none'
      })

      gsap.set('.one-pay', {
        display: 'none',
        delay: 0.5
      })

      gsap.set('.one-pay-fold', {
        display: 'flex',
        delay: 0.5
      })

      gsap.set('.one-pay-bg', {
        display: 'block',
        delay: 0.5
      })

      tl.to('.one-pay-container', {
        x: this.isAR ? '-1.38667rem' : '1.38667rem',
        duration: 0,
        ease: 'none'
      })

      tl.to('.one-pay-container', {
        x: '0',
        duration: 0.5,
        ease: 'none',
        onComplete: ()=> {
          tl.kill()
        }
      })
    }
  }
}
</script>

<style lang="less">
// 首页一键购组件
.one-pay-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  right: 0;
  bottom: 2.6667rem; /*100px*2/75*/

  .one-pay__close {
    width: 0.53333rem; /*20px*2/75*/
    height: 0.32rem; /*12px*2/75*/
    background: url('/pwa_dist/images/one_click_pay/one-click-close-btn-55d04dd9ef.png')
      center center no-repeat;
    background-size: 100% 100%;
  }

  // 收起状态
  .one-pay-bg {
    background: url('/pwa_dist/images/one_click_pay/fold-bg-2de1529058.png') center center no-repeat;
    background-size: 100% 100%;
    width: 1.38667rem; /*52px*2/75*/
    height: 1.86667rem; /*70px*2/75*/
    display: none;
  }

  .one-pay-fold {
    // padding: 0.05333rem; /*2px*2/75*/
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-top: -1.81333rem; /*68px*2/75*/
    margin-right: 0.05333rem; /*2px*2/75*/
    margin-bottom: 0.05333rem; /*2px*2/75*/
    z-index: 9;

    .shipping-pic {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.28rem; /*48px*2/75*/
      height: 1.28rem; /*48px*2/75*/
      background: linear-gradient(337deg, #dffff2 12.4%, #fff 90.94%);

      &__img {
        width: 1.01333rem; /*38px*2/75*/
        height: 0.64rem; /*24px*2/75*/
        background: url('/pwa_dist/images/one_click_pay/free-shipping-car-70d6d7fe5c.png')
          center center no-repeat;
        background-size: 1.01333rem auto;
      }
    }

    .shipping-timer {
      position: relative;
      width: 1.28rem; /*48px*2/75*/
      height: 0.32rem; /*12px*2/75*/
      margin-top: -0.26667rem; /*10px*2/75*/
      color: #000;
      text-align: center;
      font-family: 'SF UI Text';
      font-size: 0.21333rem; /*8px*2/75*/
      font-style: normal;
      font-weight: 700;
      background-color: white;
    }

    .shipping-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.28rem; /*48px*2/75*/
      height: 0.42667rem; /*16px*2/75*/
      text-align: center;
      font-size: 0.21333rem; /*8px*2/75*/
      border-radius: 0.21333rem; /*8px*2/75*/
      background: #000;

      color: #fff;
      font-family: Roboto;
      font-style: normal;
      font-weight: 700;
    }
  }

  .one-pay {
    // display: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    right: 0;
    // width: 9.7333rem; /*351px*2/75*/
    max-width: 9.78667rem; /*367px*2/75*/
    height: 1.7067rem; /*64px*2/75*/
    padding: 0.21333rem; /*8px*2/75*/
    background: linear-gradient(180deg, #ffebdb 0%, #fff 22.22%);
    filter: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.08));
    box-sizing: border-box;
    border-radius: 0.08rem 0 0 0.08rem; /*3px*2/75*/
    border: 0.026667rem solid orange; /*1px*2/75*/

    &__recommend {
      display: flex;
      flex-direction: row;

      .one-pay__recommend-pic {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 1.0667rem; /*40px*2/75*/
        height: 1.28rem; /*48px*2/75*/
        border: 0.0267rem solid rgba(255, 255, 255, 0.8); /*1px*2/75*/
        margin-right: 0.10667rem; /*4px*2/75*/
      }

      .image {
        width: 1.01333rem; /*38px*2/75*/
        height: 1.22667rem; /*46px*2/75*/
        border-radius: 0.05333rem; /*2px*2/75*/
        object-fit: cover;
      }

      .no-goods {
        background: url('/pwa_dist/images/one_click_pay/small_free_shipping-1ad7581202.png')
          center center no-repeat;
        background-size: 100% 100%;
      }

      .price-tag {
        position: relative;
        bottom: 0.05333rem; /*2px*2/75*/
        display: flex;
        max-width: 1.01333rem; /*38px*2/75*/
        height: 0.32rem; /*24px*2/75*/
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 0.32rem; /*24px*2/75*/
        background: rgba(255, 255, 255, 0.8);
        padding: 0.01rem  0.1rem; /* 0.75px/75 */ 
        color: #fa6338;
        font-family: Roboto;
        font-size: 0.2133rem; /*16px*2/75*/
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        box-sizing: border-box;
        margin-top: -0.26667rem; /*10px*2/75*/
      }
    }

    &__detail {
      margin-left: 0.10667rem; /*4px*2/75*/
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .one-pay__detail-title {
        width: 4.2667rem; /*160px*2/75*/
        color: #000;
        font-family: Roboto;
        font-size: 0.32rem; /*24px*2/75*/
        font-style: normal;
        font-weight: 700;
        line-height: 0.38rem;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .one-pay__detail-timer {
        display: flex;
        align-items: center;
        width: 1.333rem; /*100px*2/75*/
        justify-content: space-between;
      }

      .timer-block {
        width: 0.32rem; /*12px*2/75*/
        height: 0.32rem; /*12px*2/75*/
        background: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;

        color: #fff;
        font-family: 'SF UI Display';
        font-size: 0.21333rem; /*8px*2/75*/
        font-style: normal;
        font-weight: 400;
      }

      .timer-between {
        text-align: center;
      }
    }

    &__btn {
      width: 2.21333rem; /*83px*2/75*/
      height: 1.28rem; /*48px*2/75*/
      .one-pay__btn-add {
        position: absolute;
        right: 0.32rem; /*12px*2/75*/
        margin-top: 0.42667rem; /*16px*2/75*/
        display: inline-flex;
        height: 0.64rem; /* 24px * 2 / 75 */
        width: 1.89333rem; /* 71px * 2 / 75 */
        padding: 0.13rem 0.21rem; /* 5px * 2 / 75, 8px * 2 / 75 */
        justify-content: center;
        align-items: center;
        gap: 0.21rem; /* 8px * 2 / 75 */
        flex-shrink: 0;
        background-color: #000;
        color: white;
        font-family: 'SF UI Text';
        font-size: 0.32rem; /*12px*2/75*/
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .one-pay__btn-shipping {
        position: absolute;
        right: 0.18667rem; /*7px*2/75*/
        display: inline-flex;
        max-width: 1.89333rem; /* 71px * 2 / 75 */
        height: 0.32rem; /*12px*2/75*/
        padding: 0px 0.10667rem; /*4px*2/75*/
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        background: #198055;
        font-family: 'SF UI Text';
        font-size: 0.21rem; /* 8px * 2 / 75 */
        font-style: normal;
        font-weight: 500;
        color: white;
        margin-top: 0.21rem; /* 8px * 2 / 75 */

        &::after {
          content: '';
          position: absolute;
          top: 0.32rem; /*12px*2/75*/
          right: 0;
          width: 0;
          height: 0;
          border: 0.08rem solid; /*3px*2/75*/
          border-color: #16533a transparent transparent #16533a;
        }
      }
    }
  }
}

[mir=rtl] {
  .one-pay__btn-shipping{
    left: 0;
  }
  .one-pay__close{
    transform: scaleX(-1);
  }
}
</style>
